import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import SectionBg from "../images/about-section-bg.svg"
import FooterBg from "../images/about-footer-bg.svg"
import FaceBookIcon from "../images/about-facebook.svg"
import InstagramIcon from "../images/about-instagram.svg"
import AppStoreIcon from "../images/official-appstore-icon.svg"
import MobileSectionBg from "../images/about-mobile-bg.svg"

import AboutRespect from "../images/hidden/about-respect.png"
import AboutTrust from "../images/hidden/about-trust.png"
import AboutBalance from "../images/hidden/about-balance.png"
import AboutCare from "../images/hidden/about-care.png"
import AboutCommunication from "../images/hidden/about-communication.png"
import AboutLearning from "../images/hidden/about-learning.png"


const AboutPage = () => (
	<Layout>
	<SEO title="About MealPrepPro" description="MealPrepPro is an app that makes healthy eating easy. We provide hundreds of recipes personalized to you and your partners needs."/>
    <meta name="apple-itunes-app" content="app-id=1249805978" />
    <section className="about-hero">
    	<div className="container">
    		<div className="flex-row">
    			<div className="col">
    				<h1>What Is <span>MealPrepPro?</span></h1>
    				<p>MealPrepPro is an app that makes healthy eating easy. Get hundreds of recipes <strong>personalized</strong> to you (and your partners) needs.</p>
    				<p>Simply choose what you fancy eating, use our smart shopping list to order your groceries, <strong>complete your prep in under an hour</strong> and enjoy tasty healthy foods that are personalized to your needs.</p>
    				<a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
                        <div className="btn desktop-hidden">
                            Try For Free
                        </div>
                    </a>
    			</div>
    			<div className="col">
    				<div className="background-image desktop-hidden">
    					<img src={MobileSectionBg} alt="mobile section" />
    				</div>
    				<div className="content">
	    				<h2>Why <span>meal prep?</span></h2>
	    				<p>Meal prep makes it easy to stick with a plan.  If you’re hungry and there’s a nourishing meal waiting for you in the fridge, you are <strong>10x more likely to eat it instead of defaulting to takeout or snacking on chips.</strong></p>
                        <p>Let's be real, no one has time to cook a well balanced meal 3 or 4 times a day. For days when know you won’t have time to cook, meal prep makes it easy.</p>
	    				<p>Whether you want to cook 1 extra portion or 5 extra portions, make life easier with MealPrepPro.</p>
	    			<a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
                        <div className="btn desktop-hidden">
			            Try For Free
			        </div>
                     </a>
			      </div>
    			</div>
    		</div>
    		<a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
            <div className="btn desktop-show">
                Try For Free
            </div>
            </a>
    	</div>
    </section>
    <section className="about-second">
    	<div className="about-second-bg desktop-show">
    		<img src={SectionBg} alt="about second section" />
    	</div>
    	<div className="about-second-content">
    		<label>We're a team on a mission to</label>
    		<h2>Make Healthy Eating <span>Easy</span></h2>
    		<p>We know that food habits are about as personal as it gets. That's why we’ve made a commitment to embrace our customer's unique lifestyles, habits & preferences to build an app that makes healthy eating easier than ordering takeout.</p>
    	</div>
    </section>
    <section className="about-features">
    	<div className="background-image desktop-hidden">
				<img src={MobileSectionBg} alt="mobile features" />
			</div>
    	<div className="container">
    		<div className="feature">
    			<div className="col">
    				<div className="text">
	    				<h3>Care</h3>
	    				<p>We really care about our impact on the world. That's why we're here working on our health and that's why we <b>donate 1%</b> of every MealPrepPro subscription to charity. From food banks to food waste, mental health to environmental projects we support a different cause each month.</p>
	    				</div>
    				<img src={AboutCare} alt="Icon - Care" />
    			</div>
    			<div className="col">
    			</div>
    		</div>
    		<div className="vertical-line desktop-show"></div>
    		<div className="feature">
    			<div className="col">
    			</div>
    			<div className="col">
    				<img src={AboutTrust} alt="Icon - Trust" />
    				<div className="text">
	    				<h3>Trust</h3>
	    				<p>We double, triple and quadruple check our recipes, our nutrition databases and our code. So that you can put your trust in us to deliver the most delicious, clear and <b>easy to make recipes</b> in a stress-free app.</p>
	    			</div>
    			</div>
    		</div>
    		<div className="vertical-line desktop-show"></div>
    		<div className="feature">
    			<div className="col">
    				<div className="text">
	    				<h3>Balance</h3>
	    				<p>Everybody's got to have some time out. From work to workouts, cooking to planning, from following a plan to finding those moments in between. We value making it easier for you to find balance to <b>live an enriched life.</b></p>
	    			</div>
    				<img src={AboutBalance} alt="Icon - Balance" />
    			</div>
    			<div className="col">
    			</div>
    		</div>
    		<div className="vertical-line desktop-show"></div>
    		<div className="feature">
    			<div className="col">
    			</div>
    			<div className="col">
    				<img src={AboutRespect} alt="Icon - Respect" />
    				<div className="text">
	    				<h3>Respect</h3>
	    				<p>Every person is different. Each with unique tastes and dietary requirements. That's why MealPrepPro supports all major allergens and allows you to <b>focus on recipes with ingredients that you actually enjoy!</b></p>
	    			</div>
    			</div>
    		</div>
    		<div className="vertical-line desktop-show"></div>
    		<div className="feature">
    			<div className="col">
    				<div className="text">
	    				<h3>Continuous Improvement</h3>
	    				<p>MealPrepPro is continuously evolving with new technology, new breakthroughs in the field of nutrition and as our lifestyles evolve (eh... Hello 2020?!). We'll continue to improve until we're confident that <b>healthy eating is easier than ordering take out.</b></p>
	    			</div>
    				<img src={AboutLearning} alt="Icon - Learning" />
    			</div>
    			<div className="col">
    			</div>
    		</div>
    		<div className="vertical-line desktop-show"></div>
    		<div className="feature">
    			<div className="col">
    			</div>
    			<div className="col">
    				<img src={AboutCommunication} alt="Icon - Communication" />
    				<div className="text">
	    				<h3>Communication</h3>
	    				<p>Whether it's an email update from our founder, a quick reply to a support desk question or a message into our social accounts. We seriously value being able to communicate clearly within the team and within our community.</p>
	    			</div>
    			</div>
    		</div>
    	</div>
    </section>
    <footer className="about-footer">
    	<div className="about-footer-bg desktop-show">
    		<img src={FooterBg} alt="about-footer" />
    	</div>
      <div className="container">
          <div className="flex-row menus">
            <a href="https://nibbleapps.com/terms">Terms of Service</a>
            <a href="https://nibbleapps.com/privacy">Privacy Policy</a>
            <a href="https://help.mealpreppro.com/">Got a Question?</a>
          </div>
          <div className="flex-row social">
            <a href="https://www.facebook.com/groups/mealpreppro" target="_blank"><img className="facebook" src={FaceBookIcon} alt="facebook" /></a>
            <a href="https://www.instagram.com/mealprepproapp" target="_blank"><img className="instagram" src={InstagramIcon} alt="instagram" /></a>
          </div>
        </div>
        <div className="appstore">
          <a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
            <img src={AppStoreIcon} alt="appstore" />
          </a>
        </div>
    </footer>
  </Layout>
)

export default AboutPage
